import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
} from "@material-ui/core";
import React, { FC, useEffect, useState } from "react";
import {
  AccordionIcon2,
  AccordionIcon5,
  AccordionIcon6,
} from "../../../../../../img/projectIcons";
import ObjectTasksTableHead from "../ObjectTasksTableHead/ObjectTasksTableHead";
import ObjectTasks from "../ObjectTasks/ObjectTasks";
import "./styles.scss";
import SectionMenu from "../../../ui/SectionMenu/SectionMenu";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import { IDeleteTaskModal } from "../../../../interfaces";
import { getTasks, setDeleteTaskModalData } from "../../../../actions";

const ObjectSection: FC<{
  section: any;
  deleteTaskModal: IDeleteTaskModal;
  deleteState: boolean;
  setDeleteTaskModalData: (data: IDeleteTaskModal) => void;
  tasks: any[];
  users: any;
  getTasks: (sectionId: string|number) => void
}> = ({
  section,
  tasks,
  users,
  getTasks
}) => {
  const [openAccord, setOpenAccord] = useState<boolean>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showDefault, setShowDefault] = useState<boolean>(true);

  const executorInit = (id) => {
    return users.find((user) => user?.id === id);
  };

  const executor = executorInit(section?.executor_id);

  const handleSetAnchorEl = (value) => {
    setAnchorEl(value);
  };

  const useStyles = makeStyles((theme) => ({
    MuiAccordionroot: {
      "&.MuiAccordion-root:before": {
        backgroundColor: "white",
      },
    },
    MuiAccordionSym: {
      "&>.MuiAccordionSummary-content": {
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
      },
      ".Mui-expanded": {
        minWidth: "48px",
        border: "1px solid #36EA0",
      },
    },
  }));
  const classes = useStyles();

  const onChangeAccordion = (expanded) => {
    setOpenAccord(expanded);

    if(expanded) {
      getTasks(section.objectId);
    }
  }

  useEffect(() => {
    setShowDefault(true);
  }, [tasks]);
  
  return (
    <Accordion
      expanded={openAccord}
      onChange={(e, expanded) => onChangeAccordion(expanded)}
      className="accordion_section"
      classes={{ root: classes.MuiAccordionroot }}
      style={{
        width: "98%",
        boxShadow: "none",
        marginBottom: "10px",
        borderRadius: "4px 4px 0px 0px",
      }}
    >
      <AccordionSummary
        classes={{ root: classes.MuiAccordionSym }}
        // disableTouchRipple={}
        // classes={}
        style={{
          overflow: "hidden",
          border: "1px solid #dbdbdb",
          borderRadius: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          padding: "0px 12px",
          minHeight: "46px",
          margin: "0",
        }}
      >
        <div className="left_section">
          <div style={{ paddingLeft: "15px", width: "70px" }}>
            {/* {!!fetchSectionAllTasksNumber.tasks_count ? ( */}
            <div
              className={openAccord ? "accord_vector open" : "accord_vector"}
            >
              <AccordionIcon2 />
            </div>
          </div>
          <div className="text_section">{section?.name}</div>
        </div>
        <div className="right_section">
          {section?.executor_id && (
            <div className="exec_task_obj">
              <div className="responsible avatar">
                {executor?.image && (
                  <div className="object__avatar-img-wr">
                    <img
                      className="object__avatar-img"
                      src={executor.image}
                      alt={`${executor?.name} ${executor?.surname}`}
                    />
                  </div>
                )}

                {!executor?.image && (
                  <div className="responsible avatar-text">
                    {executor?.surname.substring(0, 1)}
                    {executor?.name.substring(0, 1)}
                  </div>
                )}
              </div>
              <div>
                <div>{section?.nameTask}</div>
                <div>{executor?.name + " " + executor?.surname}</div>
              </div>
            </div>
          )}
          <div className="complet_task">
            <div>
              <AccordionIcon5 />
              <span className="count_completed">{section.completed}</span>
            </div>
          </div>
          <div className="overdue_task">
            <div>
              <AccordionIcon6 />
              <span className="count_completed"> {section.overdue}</span>
            </div>
          </div>
          <div className="status_obj_section">В работе</div>
          {/*<div className="object_section__actions">
            <button
              className="object_section__actions-btn"
              onClick={(e) => handleClick(e)}
            >
              <DotsIcon />
            </button>
          </div>*/}
          <SectionMenu
            id={1}
            anchorEl={anchorEl}
            setAnchorEl={handleSetAnchorEl}
          />
        </div>
      </AccordionSummary>
      <AccordionDetails
        style={{
          backgroundColor: "#f4f4f4",
          display: "flex",
          flexDirection: "column",
          padding: "0",
        }}
      >
        <ObjectTasksTableHead />
        
        {tasks?.map((item) => {
          if (item.project_section_id === section.id) {
            if(showDefault) setShowDefault(false);
            
            return <ObjectTasks key={item.id} task={item} />;
          }
        })}

        {showDefault && (
          <div className='object_section__no-tasks'>Нет задач...</div>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

const mapStateToProps = (state: State) => {
  return {
    users: state.commonInfo.users,
    deleteTaskModal: state.objectsPage.deleteTaskModal,
    deleteState: state.objectsPage.deleteStateRegular,
    tasks: state.objectsPage.tasks,
  };
};

const mapDispatchToProps = {
  setDeleteTaskModalData,
  getTasks
};

export default connect(mapStateToProps, mapDispatchToProps)(ObjectSection);
