import { cookieMaster } from "./utils/CookieMaster";
import { CreateNotif } from "./utils/createNotification";
import { urlApp, store } from "./App";
import { setVerified } from "./common/actions";
import { setTasksMeta } from "./pages/TasksPage/actions";
import { setTypicalTasksMeta } from "./pages/TypicalTasks/actions";
import { setBpMeta } from "./pages/BusinessPage/actions";
import { setUsersMeta } from "./pages/Users/actions";


export function responseHandler(response: any, url: string, status?: number) {
  if (response.error) {
    if (response.details) {
      Object.values(response.details).forEach((detail) => {
        if (typeof detail === "string") CreateNotif(detail);
        else (detail as string[]).forEach((text) => CreateNotif(text));
      });
    } else {
      // если битая авторизация, то перенаправлять на логин
      if (
        response.error === "Unauthenticated." &&
        window.location.pathname !== "/login"
      ) {
        cookieMaster.deleteCookie("access_token");
        localStorage.removeItem("access_token");
        window.location.replace("/login");
      }
      if (response.error === "Your email address is not verified.") {
        cookieMaster.deleteCookie("access_token");
        localStorage.removeItem("access_token");
        window.location.replace("/login");
      }
      if (
        response.error === "Unauthenticated." &&
        window.location.pathname === "/login"
      ) {
        CreateNotif("Неверно указан email или пароль!");
      }
      
      if(status !== 401) {
        CreateNotif(response.error);
      }
    }
  } else {
    if (url.includes('/api/v1/tasks?') && !url.includes('/api/v1/tasks?task_id')) {
      if(response?.meta) {
        store.dispatch(setTasksMeta(response.meta));
      }
    }
    if (url.includes('/typical-tasks')) {
      if(response?.meta) {
        store.dispatch(setTypicalTasksMeta(response.meta));
      }
    }
    if (url.includes('/typical-bp')) {
      if(response?.meta) {
        store.dispatch(setBpMeta(response.meta));
      }
    }
    
    if (url.includes('/users')) {
      if(response?.meta) {
        store.dispatch(setUsersMeta(response.meta));
      }
    }

    if (url.includes("/api/v1/projects?")) {
      return response;
    }
    if (url.includes("/api/v1/projectsections?")) {
      return response;
    }

    return response.data || response;
  }
}

export function statusCheck(
  response: any,
  responseHandlerMethod: string
): Promise<any> | undefined {
  if (response.status === 401 && window.location.pathname !== "/login") {
    cookieMaster.deleteCookie("access_token");
    window.location.pathname = "/login";
    localStorage.removeItem("access_token");
    return response[responseHandlerMethod]();
  } else if (response.status === 403) {
    store.dispatch(setVerified(false));
    return response[responseHandlerMethod]();
  } else {
    return response[responseHandlerMethod]();
  }
}
