import {
  call,
  put,
  select,
  takeEvery,
  debounce, 
  delay,
} from "redux-saga/effects";
import {
  ObjectsPageActionTypes,
  checkDeleteObjectAction,
  checkDeleteTaskAction,
  deleteObjectAction,
  deleteTaskAction,
} from "./action_types";
import { fetchData } from "../../utils/fetchData";
import { CreateNotif } from "../../utils/createNotification";
import { State } from "../../rootReducer";
import {
  IEditingObjectsFile,
  IEditingObjectsFormData,
  IObjectsServerData
} from "./interfaces";
import {
  deleteObjectFromState,
  deleteTaskSectionFromState,
  setDeleteObjectModalData,
  setDeleteTaskModalData,
  setIsShowMenuPreloader,
  setLoadingObjects,
  setObjectSectionTaskList,
  setObjectSectionsList,
  setObjects,
  setObjectsUsersForFilter,
  setObjectsEditingFormOpen,
  setMeta,
  setObjectsUsersForFilterIsLoading,
  updateObjectFilesStore, 
  setCheckCreateObjectModal,
} from "./actions";
import {
  setIsCreatingObject,
  setLoadingObject,
  setObjectEditingFormData,
  setObjectsUsersResponsible,
  setObjectsUsersResponsibleLoading,
  updateObjectsStore,
} from "./actions";
import {
  messagesObjects,
  deleteObjectStatuses,
  sortOrders,
  messagesObjectTask,
  statusObject,
  userRolesMeridian, 
  checkAddObjectStatuses,
} from "./constants";
import { ObjectsTerms, OjectsPersonal } from "../../common/constants";
import { IMeta } from "../../common/types";
import { cookieMaster } from "../../utils/CookieMaster";

export function* watchCreateObject() {
  yield takeEvery(ObjectsPageActionTypes.CREATE_OBJECT, createObject);
}

export function* watchUpdateObject() {
  yield takeEvery(ObjectsPageActionTypes.UPDATE_OBJECT, updateObject);
}

export function* watchDeleteObject() {
  yield takeEvery(ObjectsPageActionTypes.DELETE_OBJECT, deleteObject);
}

export function* watchGetObjects() {
  yield takeEvery(ObjectsPageActionTypes.GET_OBJECTS_DATA, getObjects);
}

export function* watchCheckDeleteObject() {
  yield takeEvery(
    ObjectsPageActionTypes.CHECK_DELETE_OBJECT,
    checkDeleteObject
  );
}
export function* watchGetObject() {
  yield takeEvery(ObjectsPageActionTypes.GET_OBJECT, getObject);
}

export function* watchGetUsersResponsible() {
  yield takeEvery(
    ObjectsPageActionTypes.GET_OBJECTS_USERS_RESPONSIBLE,
    getUsersResponsible
  );
}

export function* watchCheckDeleteTaskObject() {
  yield takeEvery(
    ObjectsPageActionTypes.CHECK_DELETE_TASK,
    checkDeleteTask
  );
}

export function* watchDeleteTaskSectionObject() {
  yield takeEvery(ObjectsPageActionTypes.DELETE_TASK_SECTION, deleteTask);
}


export function* watchGetUsersDataForObjectsFilter() {
  yield takeEvery(
    ObjectsPageActionTypes.GET_OBJECTS_USERS_FOR_FILTER,
    getUsersDataForFilter
  );
}

export function* watchSearchObjects() {
  yield debounce(500, ObjectsPageActionTypes.SET_SEARCH_OBJECTS, getObjects);
}

export function* watchGetObjectSection() {
  yield takeEvery(ObjectsPageActionTypes.GET_OBJECT_SECTION, getObjectSectionsTasks);
}

export function* watchGetTaskObjectSection() {
  yield takeEvery(ObjectsPageActionTypes.GET_TASKS, getTasks);
}

function* getTasks( { sectionId }: any) {
  yield put(setLoadingObjects(true));
  
  const response: any = yield call(
    fetchData.get,
    `/api/v1/tasks?project_section_id=${sectionId}`
  );

  if(response?.length) {
    yield put(setObjectSectionTaskList(response));
  }
  
  yield put(setLoadingObjects(false));
}

export function* getObjectSectionsTasks({objectId}: any) {
  yield put(setLoadingObjects(true));

  const responseSections: any = yield call(
    fetchData.get, 
    `/api/v1/projects/${objectId}/sections`
  );

  if(responseSections.length) {
    const responseTasksStatistic: any = yield call(getTaskStatistics, responseSections[0].project_id);

    if(responseTasksStatistic) {
      yield put(setObjectSectionsList([{
        id: responseSections[0].id,
        name: responseSections[0].name,
        completed: responseTasksStatistic.completed_tasks_count,
        overdue: responseTasksStatistic.overdue_tasks_count,
        // status: 3, ??
        // executor_id ??
        objectId
      }]));
    }
  }
  
  yield put(setLoadingObjects(false));
}

function* getTaskStatistics(sectionId) {
  let response = yield call(
    fetchData.get,
    `/api/v1/projectsections/${sectionId}/task-statistics`
  );
  
  return response;
}

function* getUsersResponsible() {
  yield put(setObjectsUsersResponsibleLoading(true));
  
  let response = yield call(
    fetchData.get, 
    `/api/v1/users?roles[]=${userRolesMeridian.ADMIN}&roles[]=${userRolesMeridian.DIRECTOR}&roles[]=${userRolesMeridian.CURATOR}`
  );

  if(response) {
    yield put(setObjectsUsersResponsible(response));
  }

  yield put(setObjectsUsersResponsibleLoading(false));
}

function* getObjects({ params, action }: any) {
  let url = "?";
  let convertedData = [];

  const mapValuesTerm = {
    [ ObjectsTerms.OVERDUE_STATUS]: { less: 0 },
    [ ObjectsTerms.LESS_THREETY_STATUS]: { more: 0, less: 30 },
    
    [ ObjectsTerms.MORE_THREETY_STATUS ]: { more: 30, less: 60 },
    [ ObjectsTerms.MORE_SIXTY_STATUS ]: { more: 60, less: 100 },
    [ ObjectsTerms.MORE_HUNDRED_STATUS ]: { more: 100 }
  };
  
  const currentPage = yield select(
    (state: State) => state.objectsPage.currentPage
  );
  const sortConditions = yield select(
    (state: State) => state.objectsPage.sortCondition
  );
  const search = yield select(
    (state: State) => state.objectsPage.searchObjects
  );
  const filters = yield select((state: State) => state.objectsPage.filters);
  
  if(sortConditions) {
    let columnId = sortConditions.columnId;
    
    url += `&order=${columnId}`;
    
    if(sortConditions.value === sortOrders.DESC) {
      url += '&orderType=desc';
    }
  }

  if (filters) {
    if (filters.terms.length) {
      filters.terms.forEach((term) => {
        if(mapValuesTerm[term]?.more) {
          url += `&agreement_days_left_from=${mapValuesTerm[term].more}`;
        }
        
        if(typeof mapValuesTerm[term]?.less === 'number') {
          url += `&agreement_days_left_to=${mapValuesTerm[term].less}`;
        }
      });
    }

    if(filters.usersIds?.length && !filters.usersIds.includes(0)) {
      filters.usersIds.forEach((userId) => {
        url += `&project_manager_id[]=${userId}`;
      });
    }

    if (filters.personal === OjectsPersonal.PERSONAL_STATUS) {
      url += `&private=true`;
    }
  }
  
  if(search?.length) {
    url += `&search=${search}`;
  }

  if (currentPage) {
    url += `&page=${currentPage}`;
  }

  yield put(setLoadingObjects(true));
  
  const response: {
    data: IObjectsServerData[],
    meta: IMeta
  } = yield call(
    fetchData.get,
    `/api/v1/projects${url}`
  );
  
  if(response?.data) {
    convertedData = convertObjectFromServerToFrontDataHelper(response.data);
    
    yield put(setObjects(convertedData, action));
  }
  
  if(response?.meta) {
    yield put(setMeta(response.meta));
  }
  
  yield put(setLoadingObjects(false));
}

function* getUsersDataForFilter() {
  yield put(setObjectsUsersForFilterIsLoading(true));
  
  // По ТЗ тут должны загружаться только Ответственный РП. Сейчас грузятся все юзеры
  let response = yield call(
    fetchData.get, 
    `/api/v1/users?roles[]=${userRolesMeridian.ADMIN}&roles[]=${userRolesMeridian.DIRECTOR}&roles[]=${userRolesMeridian.CURATOR}`
  );

  if (response) {
    yield put(setObjectsUsersForFilter(response));
  }

  yield put(setObjectsUsersForFilterIsLoading(false));
}

function* checkDeleteObject({ id }: checkDeleteObjectAction) {
  yield put(setIsShowMenuPreloader(true));

  let response: any = null;

  /* response = yield call(
    fetchData.get,
    `/api/v1/object/${id}/check-delete`
  );*/

  // if (response?.constraints.length === 0) {
  //   response = { constraints: [deleteObjectStatuses.DELETE_OBJECT_ALLOWED] };
  // }

  // if (!response) {
  //   response = { constraints: [deleteObjectStatuses.DELETE_OBJECT_ERROR] };
  // }

  yield put(
    setDeleteObjectModalData({
      objectId: id,
      isDeleteModalOpen: true,
      statusFromServer: {
        constraints: [deleteObjectStatuses.DELETE_OBJECT_ALLOWED as string],
      },
    })
  );

  yield put(setIsShowMenuPreloader(false));
}

function* checkDeleteTask({ id }: checkDeleteTaskAction) {
  yield put(setIsShowMenuPreloader(true));

  let response: any = null;

  /* response = yield call(
    fetchData.get,
    `/api/v1/object/${id}/check-delete`
  );*/

  // if (response?.constraints.length === 0) {
  //   response = { constraints: [deleteObjectStatuses.DELETE_OBJECT_ALLOWED] };
  // }

  // if (!response) {
  //   response = { constraints: [deleteObjectStatuses.DELETE_OBJECT_ERROR] };
  // }

  yield put(
    setDeleteTaskModalData({
      taskId: id,
      isDeleteTaskModalOpen: true,
      statusTaskFromServer: {
        constraints: [deleteObjectStatuses.DELETE_OBJECT_ALLOWED as string],
      },
    })
  );

  yield put(setIsShowMenuPreloader(false));
}

function* deleteObject({ id }: deleteObjectAction) {
  const errorStatuses = [403, 404, 500];

  yield put(setDeleteObjectModalData({ isShowPreloader: true }));
  
  yield delay(250); // Для красоты интерфейса
  
  const response = yield call(
    fetchData.delete, 
    `/api/v1/projects/${id}`
  );

  yield put(
    setDeleteObjectModalData({
      isDeleteModalOpen: false,
      isShowPreloader: false,
    })
  );
  
  if (response?.status === 204) {
    yield put(deleteObjectFromState(id));
    CreateNotif(messagesObjects.DELETE_OBJECT_SUCCESS, "success");
  }

  if (errorStatuses.includes(response?.status)) {
    CreateNotif(messagesObjects.DELETE_OBJECT_ERROR);
  }
}

function* deleteTask({ taskId }: deleteTaskAction) {
  const errorStatuses = [403, 404, 500];

  yield put(setDeleteTaskModalData({ isShowTaskPreloader: true }));
  
  yield delay(250); // Для красоты интерфейса
  
  const response = yield call(
    fetchData.delete, 
    `/api/v1/tasks/${taskId}`
  );
  
  yield put(
    setDeleteTaskModalData({
      isDeleteTaskModalOpen: false,
      isShowTaskPreloader: false,
    })
  );

  if (response?.status === 204) {
    yield put(deleteTaskSectionFromState(taskId));
    CreateNotif(messagesObjectTask.DELETE_OBJECT_TASK_SUCCESS, "success");
  }

  if (errorStatuses.includes(response?.status)) {
    CreateNotif(messagesObjectTask.DELETE_OBJECT_TASK_ERROR);
  }
}
  
function* createObject({ data, files }: any) {
  yield put(setIsCreatingObject(true));

  let fileResponse: any = null;
  
  if(files?.length) {
    fileResponse = yield call(uploadFilesHelper, files);

    if(fileResponse) {
      data.files = fileResponse;
    }
    else {
      data.files = [];
    }
  }

  const convertedData = yield convertFrontToServerDataHelper(data);

  const response: any = yield call(
    fetchData.post,
    `/api/v1/projects`,
    JSON.stringify(convertedData),
    'returnFullResponse'
  );

  if(response.status === 422) {
    yield put(
      setCheckCreateObjectModal({
        isModalOpen: true,
        statusFromServer: {
          constraints: [checkAddObjectStatuses.ADD_OBJECT_ERROR as string],
        }
      })
    );
  }

  if(response?.data) {
    const responseConvertedData = convertObjectFromServerToFrontDataHelper([response.data]);

    yield put(setObjects(responseConvertedData[0]));

    CreateNotif(messagesObjects.CREATE_OBJECT_SUCCESS, "success");
    
    yield put(setObjectsEditingFormOpen(false));
  }

  yield put(setIsCreatingObject(false));
}

function* uploadFilesHelper(data) {
  let filesResponse: any = [];

  if(data?.length) {
    for(let file of data) {
      const formData = new FormData();
      formData.append('file', file);

      const response = yield call(
        fetchData.post,
        '/api/v1/files',
        formData, {
          Authorization: cookieMaster.getCookie('access_token') // Это обязательно нужно передавать
        });
      
      if(response) {
        filesResponse.push(response);
      }
    }
  }

  return filesResponse;
}

function* updateObject({ data, files }: any) {
  yield put(setIsCreatingObject(true));

  let fileResponse: any = null;
  
  if(files?.length) {
    fileResponse = yield call(uploadFilesHelper, files);
    
    if(fileResponse) {
      data.files = fileResponse;
    }
    else {
      data.files = [];
    }
  }

  const convertedData = yield convertFrontToServerDataHelper(data);
  
  const response: any = yield call(
    fetchData.patch,
    `/api/v1/projects/${convertedData.id}`,
    JSON.stringify(convertedData),
    'returnFullResponse'
  );
  
  if(response.status === 422) {
    yield put(
      setCheckCreateObjectModal({
        isModalOpen: true,
        statusFromServer: {
          constraints: [checkAddObjectStatuses.UPDATE_OBJECT_ERROR as string],
        }
      })
    );
  }

  if(response?.data) {
    const convertedDataServer = convertObjectFromServerToFrontDataHelper([response.data]);
    
    if(convertedDataServer.length) {
      yield put(updateObjectsStore(convertedDataServer[0]));
      yield put(updateObjectFilesStore(convertedDataServer[0]?.files))
    }

    CreateNotif(messagesObjects.UPDATE_OBJECT_SUCCESS, "success");
  }

  yield put(setIsCreatingObject(false));
}

function* getObject({ id }: any) {
  yield put(setLoadingObject(true));
  
  let response: any = yield call(
    fetchData.get,
    `/api/v1/projects/${id}`
  );

  if(response) {
    const convertedData = yield call(convertServerToFrontFORMDataHelper, response);

    yield put(setObjectEditingFormData(convertedData));
  }

  yield put(setLoadingObject(false));
}

function* convertServerToFrontFORMDataHelper(data: IObjectsServerData): any {
  let result: IEditingObjectsFormData = {
    name: data.name,
    title: data.description,
    responsible: {
      userSelected: {
        value: data.project_manager.id ?? '', 
        label: (data.project_manager.surname + ' ' + data.project_manager.name) ?? ''
      }
    },
    declarant: data.applicant,
    numberSap: data.sap_number,
    contractNumber: data.agreement_number,
    startDate: data.agreement_start_date,
    endDate: data.agreement_end_date,
    cost: (data.agreement_amount > 0) ? data.agreement_amount / 100 : 0, // Сервер возвращает сумму в копейках, т.е. целое число
    createdAt: data.created_at,
    sections: data.sections,
    commentsCount: data.comments_count,
    documentsCount: data.files_count,
  };
  
  if(data.files?.length) {
    result.entryDocuments = filesDataConvertHelper(data.files);
  }
  
  if(data.author_id) {
    result.author = yield call(authorDataConvertHelper, data.author_id);
  }

  return result;
}

function filesDataConvertHelper(data): IEditingObjectsFile[] {
  const result: IEditingObjectsFile[] = data.map(file => ({
    id: file.id,
    name: file.name,
    link: file.url
  }));
  
  return result;
}

function *authorDataConvertHelper(authorId: string|number) {
  if(!authorId) return null;
  
  const users = yield select((state: State) => state.commonInfo.users);
  const result = users.filter(user => user.id === authorId);

  if(result.length) {
    return result[0];
  }
  
  return null;
}

export function convertObjectFromServerToFrontDataHelper(data: IObjectsServerData[]): any {
  let result: any = [];
  
  if(data.length) {
    result = data.map((el: any) => {
      if(el.files?.length) {
        el.files = el.files.map(file => ({
          ...file,
          link: file.url ?? ''
        }));
      }
      
      return {
        id: el.id,
        name: el.name,
        description: el.description,
        files: el.files,
        spectators: el.spectators,
        completed_tasks_count: el.completed_tasks_count,
        overdue_tasks_count: el.overdue_tasks_count,
        project_manager_id: el.project_manager?.id,
        applicant: el.applicant,
        sap_number: el.sap_number,
        agreement_number: el.agreement_number,
        start_at: el.agreement_start_date,
        end_date: el.agreement_end_date,
        agreement_amount: el.agreement_amount,
        status_id: el.status_id ?? statusObject.IN_WORK_STATUS,
        daysDifference: el.agreement_days_left,
        work: el.tasks_in_work_count,
        author_id: el.author_id,
        project_manager: el.project_manager
      }
    });
  }
  
  return result;
}

function* convertFrontToServerDataHelper(data: any) {
  let result: any = {
    id: data.id ?? '',
    name: data.name,
    applicant: data.declarant,
    description: data.title,
    sap_number: data.numberSap,
    project_manager_id: data.responsible.value,
    agreement_number: data.contractNumber,
    agreement_amount: data.cost * 100, // Сервер ожидает сумму в копейках, те должно быть целое число
    agreement_start_date: data.startDate,
    agreement_end_date: data.endDate,
    spectators: [],
    workflow_id: 1, // всегда передается 1, взял из проектов, зачем и почему так никто не знает
  };
  
  if(data.files?.length) {
    let filesId: any = [];
    
    data.files.forEach(file => {
      filesId.push(file.id);
    })
    
    if(filesId.length) {
      result.files = filesId;
    }
  }
  
  return result;
}
