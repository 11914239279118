import React, { useState, useEffect, FC } from "react";
import { connect } from "react-redux";
import { State } from "../../../../../../rootReducer";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import {
  AccordionIcon2,
  AccordionIcon4,
  AccordionIcon5,
  AccordionIcon6,
} from "../../../../../../img/projectIcons";
import { ReactComponent as DotsIcon } from "../../../../../../img/TrueIcons/dots.svg";
import { makeStyles } from "@material-ui/core/styles";
import "./styles.scss";
import MenuObject from "../../../ui/ObjectMenu/MenuObject";
import ObjectSection from "../StageObject/ObjectSection";
import moment from "moment";
import { statusObject } from "../../../../constants";
import { getObjectSection } from "../../../../actions";

const Object: FC<{ 
  object: any; 
  users: any; 
  sections: any[],
  getObjectSection: (objectId: string|number) => void
}> = ({
  object,
  users,
  sections,
  getObjectSection
}) => {
  const [openAccord, setOpenAccord] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [backgroundColor, setBackgroundColor] = useState<string>("green");

  const executorInit = (id) => {
    return users.find((user) => user?.id === id);
  };

  const useStyles = makeStyles((theme) => ({
    MuiAccordionroot: {
      "&.MuiAccordion-root:before": {
        backgroundColor: "white",
      },
    },
    accordionTitle: {
      position: "relative",
      cursor: "pointer",
      "&::before": {
        content: '""',
        position: "absolute",
        dislay: "inline-block",
        top: 0,
        left: 0,
        width: "10px",
        height: "100%",
        backgroundColor: (props: any) => props.backgroundColor || "transparent",
        opacity: 0.5,
        zIndex: 10,
        borderRadius: "4px 0 0 0",
      },
    },
  }));

  const setColorBefore = () => {
    if (object?.daysDifference > 100) {
      setBackgroundColor("green");
    } else if (
      object?.daysDifference > 60 &&
      object?.daysDifference <= 100
    ) {
      setBackgroundColor("rgba(10, 255, 14, 1)");
    } else if (object?.daysDifference > 30 && object?.daysDifference <= 60) {
      setBackgroundColor("yellow");
    } else if (object?.daysDifference > 0 && object?.daysDifference <= 30) {
      setBackgroundColor("orange");
    } else {
      setBackgroundColor("red");
    }
  };
  const classes = useStyles({ backgroundColor });

  const handleSetAnchorEl = (value) => {
    setAnchorEl(value);
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    event.stopPropagation();
  };

  useEffect(() => {
    setColorBefore();
  }, [object?.daysDifference]);

  const currentDate = moment();
  const dateTreaty = moment(object?.end_date, "YYYY-MM-DD");

  const statusObj = (status) => {
    if (status === statusObject.IN_WORK_STATUS) {
      if (dateTreaty.isBefore(currentDate)) {
        return "Просрочено";
      } else {
        return "В работе";
      }
    } else if (status === statusObject.COMPLETED_STATUS) {
      return "Завершено";
    } else if (status === statusObject.MADE_STATUS) {
      return "Выполнено";
    } else if (status === statusObject.DRAFT_STATUS) {
      return "Черновик";
    } else if (status === statusObject.CANCELED_STATUS) {
      return "Отменено";
    } else {
      return "";
    }
  };
  
  const onChangeAccordion = (expanded) => {
    setOpenAccord(expanded);
    
    if(expanded) {
      getObjectSection(object.id);
    }
  }

  return (
    <>
      <Accordion
        className="object_accordion"
        expanded={openAccord}
        onChange={(e, expanded) => onChangeAccordion(expanded)}
        classes={{ root: classes.MuiAccordionroot }}
      >
        <AccordionSummary
          className="object-AccordionSym"
          classes={{
            root: classes.accordionTitle,
          }}
        >
          <div className="header_accordion">
            <div className="right_section_obj">
              <div
                className={openAccord ? "accord_vector open" : "accord_vector"}
              >
                <AccordionIcon2 />
              </div>
              <div>
                <div className="id_object">{object?.id}</div>
              </div>
              <div className="day_section">{object?.daysDifference ?? '—'}</div>
              <div className="name_obj">{object?.name}</div>
            </div>
            <div className="center_block">
              <div className="treaty_obj">
                {/* Из ТЗ: В будущем будет добавлено отображение «типа договора» */}
                {/*<div className="type_treaty">Комплексный</div>*/}
                <div className="name_treaty">{object?.agreement_number ?? '—'}</div>
              </div>
              <div className="responsible_task">
                <div className="responsible avatar">
                  {executorInit(object?.project_manager_id)?.image && (
                    <div className="object__avatar-img-wr">
                      <img
                        className="object__avatar-img"
                        src={executorInit(object?.project_manager_id)?.image}
                        alt={
                          executorInit(object?.project_manager_id)?.name +
                          executorInit(object?.project_manager_id)?.surname
                        }
                      />
                    </div>
                  )}

                  {!executorInit(object?.project_manager_id)?.image && (
                    <div className="responsible avatar-text">
                      {executorInit(
                        object?.project_manager_id
                      )?.surname.substring(0, 1)}
                      {executorInit(object?.project_manager_id)?.name.substring(
                        0,
                        1
                      )}
                    </div>
                  )}
                </div>
                <div
                  className="responsible_name"
                  title={
                    executorInit(object?.project_manager_id)?.name + ' ' +
                    executorInit(object?.project_manager_id)?.surname
                  }
                >
                  {
                    executorInit(object?.project_manager_id)?.name + ' ' +
                    executorInit(object?.project_manager_id)?.surname
                  }
                </div>
              </div>

              <div className="bp_name_object">
                <div>ПИР</div>
                {/* Из ТЗ: В текущей реализации всегда отображается наименование единственного этапа «ПИР».*/}
                {/*<div className="bp_name_obj">Бизнес-процесс</div>*/}
              </div>
            </div>
            <div className="left_section_obj">
              <div className="tasks_in_work">
                  <AccordionIcon4/>
                  <span className="tasks_in_work__count">
                    {object?.work ?? 0}
                  </span>
              </div>
              <div className="completed_tasks_obj">
                <div>
                  <AccordionIcon5 />
                  <span className="count_completed">{object?.completed_tasks_count}</span>
                </div>
              </div>
              <div className="overdue_task_obj">
                <div>
                  <AccordionIcon6 />
                  <span className="count_completed">{object?.overdue_tasks_count}</span>
                </div>
              </div>
              <div className="status_object">{statusObj(object?.status_id)}</div>
              <div className="object__actions">
                <button
                  className="object__actions-btn"
                  onClick={(e) => handleClick(e)}
                >
                  <DotsIcon />
                </button>
              </div>
              <MenuObject
                id={object?.id}
                anchorEl={anchorEl}
                setAnchorEl={handleSetAnchorEl}
              />
            </div>
          </div>
        </AccordionSummary>
        <AccordionDetails
          classes={{ root: classes.MuiAccordionroot }}
          style={{
            background: "#FFFFFFF",
            alignItems: "center",
            padding: "0 0 0 25px",
            display: "block",
          }}
        >
          {sections.map((item) => {
            if (item.objectId === object.id) {
              return <ObjectSection section={item} key={item.id} />;
            }
          })}
        </AccordionDetails>
      </Accordion>
    </>
  );
};

const mapStateToProps = (state: State) => {
  return {
    users: state.commonInfo.users,
    sections: state.objectsPage.sections,
  };
};

const mapDispatchToProps = {
  getObjectSection
};

export default connect(mapStateToProps, mapDispatchToProps)(Object);
