import { State } from "../../../../rootReducer";
import { ITypicalTaskServerData } from "../../../TypicalTasks/interfaces";
import { createSelector } from "reselect";
import { getUsers } from "../../../../common/selectors";
import { IObjectsFormAddTaskDataSelect } from "./interfaces";
import { IObjectsServerData } from "../../interfaces";

export const getTypicalTasksSelector = (state: State): ITypicalTaskServerData[] => state.typicalTasksPage.tasks;
export const getTypicalTasksForSelectSelector =
    (state: State): ITypicalTaskServerData[] => state.businessPage.extraFormBP.typicalTasksBp
export const getSelectedTypicalTask = (state: State): IObjectsFormAddTaskDataSelect => state.objectsFormAddTask.data.typicalTasks.selectedValue;
export const getObjectsTasksSelector = (state: State): IObjectsServerData[] => state.objectsPage.objects;

export const getTypicalTasksForSelect = createSelector(
  [getTypicalTasksForSelectSelector],
  (typicalTasks) =>
  {
    let result: IObjectsFormAddTaskDataSelect[] = [{value: '', label: ''}];
    
    if(typicalTasks.length) {
      result = typicalTasks.map(task => ({
        value: task.id,
        label: task.name
      }));
    }

    return result;
  }
);

export const getTypicalTaskExecutor = createSelector(
  [getSelectedTypicalTask, getTypicalTasksSelector],
  (selectedTypicalTask, typicalTasks) =>
  {
    let task: ITypicalTaskServerData;
    let result: IObjectsFormAddTaskDataSelect = {value: '', label: 'Введите значение...'};

    if(typicalTasks.length) {
      task = typicalTasks.filter(task => task.id === selectedTypicalTask.value)[0];

      if(task) {
        result = {
          value: task.executor?.id ?? '',
          label: task.executor?.surname + ' ' + task.executor?.name
        }
      }
    }

    return result;
  }
)

export const getAllExecutors = createSelector(
  [getUsers],
  (users) =>
  {
    let result: IObjectsFormAddTaskDataSelect[] = [{value: '', label: 'Введите значение...'}];

    if(users.length) {
      result = users.map(user => ({
        value: user.id,
        label: user.surname + ' ' + user.name
      }));
    }

    return result;
  }
)

export const getDelegate = createSelector(
  [getSelectedTypicalTask, getTypicalTasksSelector],
  (selectedTypicalTask, typicalTasks) =>
  {
    let task: ITypicalTaskServerData;
    let result: boolean = false;

    if(typicalTasks.length) {
      task = typicalTasks.filter(task => task.id === selectedTypicalTask.value)[0];
      
      if(task) {
        result = !!task.delegate;
      }
    }

    return result;
  }
)

export const getDuration = createSelector(
  [getSelectedTypicalTask, getTypicalTasksSelector],
  (selectedTypicalTask, typicalTasks) =>
  {
    let task: ITypicalTaskServerData;
    let result: string = '';

    if(typicalTasks.length) {
      task = typicalTasks.filter(task => task.id === selectedTypicalTask.value)[0];

      if(task) {
        result = task?.duration ?? ''
      }
    }

    return result;
  }
)

export const getDescription = createSelector(
  [getSelectedTypicalTask, getTypicalTasksSelector],
  (selectedTypicalTask, typicalTasks) =>
  {
    let task: ITypicalTaskServerData;
    let result: string = '';

    if(typicalTasks.length) {
      task = typicalTasks.filter(task => task.id === selectedTypicalTask.value)[0];

      if(task) {
        result = task?.description ?? ''
      }
    }
    
    return result;
  }
)

export const getAdditionalFields = createSelector(
  [getSelectedTypicalTask, getTypicalTasksSelector],
  (selectedTypicalTask, typicalTasks) =>
  {
    let task: ITypicalTaskServerData;
    let result: any = {};

    if(typicalTasks.length) {
      task = typicalTasks.filter(task => task.id === selectedTypicalTask.value)[0];

      if(task) {
        result = {
          checklist: task.checklist,
          extra: task.extra
        }
      }
    }
    
    return result;
  }
)

export const getObjectsTasksForSelect = createSelector(
  [getObjectsTasksSelector],
  (objects) =>
  {
    let result: IObjectsFormAddTaskDataSelect[] = [{value: '', label: ''}];
    
    if(objects.length) {
      result = objects.map(task => ({
        value: task.id,
        label: task.name
      }));
    }

    return result;
  }
);

