import React, { ChangeEvent, FC, useEffect, useRef, useState } from "react";
import {connect, useDispatch} from "react-redux";
import { State } from "../../../../rootReducer";
import CheckboxMui from "../../../../common/components/ui/CheckboxMui/CheckboxMui";
import {
  IFormAddTaskAdditionalFields,
  IObjectsFormAddTaskData,
  IObjectsFormAddTaskDataSelect,
} from "./interfaces";
import { IEditingObjectsFile } from "../../interfaces";
import SelectSimple from "../../../../common/components/ui/SelectSimple/SelectSimple";
import InputSimple from "../../../../common/components/ui/InputSimple/InputSimple";
import PriorityCustom from "../../../../common/components/ui/PriorityCustom/PriorityCustom";
import TooltipQuestion from "../../../../common/components/ui/TooltipQuestion/TooltipQuestion";
import SelectObserversObjects from "../ui/SelectObservers/SelectObservers";
import moment, { Moment } from "moment/moment";
import DatePickerTrue from "../ui/DatePickerTrue/DatePickerTrue";
import {
  datePickerFormat,
  datePickerText,
} from "../ui/DatePickerTrue/constants";
import { nanoid } from "nanoid";
import FileItem from "../ui/FileItem/FileItem";
import TextAreaSimple from "../../../../common/components/ui/TextAreaSimple/TextAreaSimple";

import { ReactComponent as CloseIcon } from "../../../../img/TrueIcons/close.svg";
import { ReactComponent as TableIcon } from "../../../../img/TrueIcons/table.svg";

import PreloaderLocal from "../../../../common/components/PreloaderLocal/PreloaderLocal";
import {
  setCheckTrigger,
  setCheckbox,
  setFile,
  setFormOpen,
  setInput,
  setSelectObservers,
  setSelectSelectedValue,
  setSelectValues,
  createTask,
  getObjectSectionData,
  setFormDefaultValues,
} from "./actions";
import {getTypicalTasks} from "../../../TypicalTasks/actions";
import {
  getAllExecutors,
  getDuration,
  getDelegate,
  getTypicalTaskExecutor,
  getTypicalTasksForSelect,
  getDescription,
  getAdditionalFields,
  getObjectsTasksForSelect,
} from "./selectors";
import AdditionalBlock from "../AdditionalBlock/AdditionalBlock";
import { setDefaultValues, setTaskFormData } from "../FormEditTask/actions";
import { IObjectsFormEditTaskData } from "../FormEditTask/interfaces";
import { getObjects } from "../../actions";

import "./FormAddTask.scss";
import {getTypicalTasksBp, setCurrentPageTypicalTasksBp, setSearchTypicalTasksBp} from "../../../BusinessPage/actions";


const FormAddTask: FC<{
  isCreatingTask: boolean;
  isFormOpen: boolean;
  formData: IObjectsFormAddTaskData;
  setSelectValues: (
    data: IObjectsFormAddTaskDataSelect[],
    name: keyof IObjectsFormAddTaskData
  ) => void;
  setSelectSelectedValue: (
    data: IObjectsFormAddTaskDataSelect,
    name: keyof IObjectsFormAddTaskData
  ) => void;
  setCheckbox: (value: boolean, name: keyof IObjectsFormAddTaskData) => void;
  setInput: (value: string | Date, name: keyof IObjectsFormAddTaskData) => void;
  typicalTasksForSelect: IObjectsFormAddTaskDataSelect[];
  executor: IObjectsFormAddTaskDataSelect;
  executorsAll: IObjectsFormAddTaskDataSelect[];
  delegate: boolean;
  setSelectObservers: (ids: number[]) => void;
  duration: string;
  description: string;
  setFile: (file: IEditingObjectsFile) => void;
  setFormOpen: (isFormOpen: boolean) => void;
  additionalFields: IFormAddTaskAdditionalFields;
  setCheckTrigger: (checkTrigger: string) => void;
  createTask: (data: object) => void;
  objects: any;
  getObjectSectionData: (objectId: string | number) => void;
  setTaskFormData: (
    taskFormData: IObjectsFormEditTaskData | null,
    additionalFields: any
  ) => void;
  setDefaultValues: () => void;
  objectsForSelect: any;
  isAddTask?: boolean;
  getObjects: (params?: object) => void;
  setFormDefaultValues: () => void;
  checkTrigger: string;
  typicalTasksMeta?: any;
  currentPage?: number
}> = ({
  isCreatingTask,
  isFormOpen,
  formData,
  setSelectValues,
  setSelectSelectedValue,
  setCheckbox,
  setInput,
  typicalTasksForSelect,
  executor,
  executorsAll,
  delegate,
  setSelectObservers,
  duration,
  description,
  setFile,
  setFormOpen,
  additionalFields,
  setCheckTrigger,
  createTask,
  objects,
  getObjectSectionData,
  setTaskFormData,
  setDefaultValues,
  objectsForSelect,
  isAddTask,
  getObjects,
  setFormDefaultValues,
  checkTrigger,
  typicalTasksMeta,
  currentPage,
}) => {
  const formTasksRef = useRef<HTMLDivElement | null>(null);
  const [filesToUpload, setFilesToUpload] = useState<any>();
  const [dateErrorMessages, setDateErrorMessages] = useState<any>({
    startDate: [],
    endDate: [],
  });
  const [showFullForm, setShowFullForm] = useState<boolean>(false);
  const [showSecondStage, setShowSecondStage] = useState<boolean>(false);

  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);
  const dispatch = useDispatch();

  const onCloseHandler = () => {
    setFormOpen(false);
    setFormDefaultValues();
    setDateErrorMessages({ startDate: [], endDate: [] });
  };

  const onChangeDate = (
    date: Date | null,
    name: keyof IObjectsFormAddTaskData
  ) => {
    getDeadlineHelper(duration, date);

    if (date) {
      setInput(date, name);
    }
  };

  useEffect(() => {
    if(checkTrigger.length) {
      checkDateRequired();
    }
  }, [checkTrigger]);
  
  const checkDateRequired = () => {
    if(!moment(formData.startDate).isValid()) {
      setDateErrorMessages(( state ) => {
        return {
          ...state,
          startDate: ['Поле обязательно для заполнения'],
        };
      });
    }

    if(!moment(formData.endDate).isValid()) {
      setDateErrorMessages(( state ) => {
        return {
          ...state,
          endDate: ['Поле обязательно для заполнения'],
        };
      });
    }
  }
  
  const checkDateHelper = (date: string | Moment, name: string) => {
    setDateErrorMessages({ startDate: [], endDate: [] });

    let isStartDateLessEndDate = false;
    let isEndDateLessStartDate = false;

    if (name === "startDate") {
      isStartDateLessEndDate = moment(
        formData.endDate,
        datePickerFormat.STANDARD_DATE_TIME
      ).isBefore(date, "date");
    }
    if (name === "endDate") {
      isEndDateLessStartDate = moment(
        formData.startDate,
        datePickerFormat.STANDARD_DATE_TIME
      ).isAfter(date, "minutes");
    }

    if (isStartDateLessEndDate) {
      setDateErrorMessages((state) => {
        return {
          ...state,
          startDate: [
            ...state.startDate,
            "Дата начала не может быть больше даты окончания",
          ],
        };
      });
    }

    if (isEndDateLessStartDate) {
      setDateErrorMessages((state) => {
        return {
          ...state,
          endDate: [
            ...state.endDate,
            "Дата окончания не может быть меньше даты начала",
          ],
        };
      });
    }

    checkDateRequired();
  };

  const getDeadlineHelper = (duration: string, date) => {
    let deadlineTime = "";

    let timeValue = moment.duration(duration).asDays();
    let timeType = "days";

    if (timeValue === 0) {
      timeValue = moment.duration(duration).hours();
      timeType = "hours";
    }

    // @ts-ignore
    deadlineTime = moment(date).add(timeValue, timeType);

    setInput(deadlineTime, "endDate");

    checkDateHelper(date, "endDate");
  };

  const onChangeCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    name: keyof IObjectsFormAddTaskData
  ) => {
    setCheckbox(event.target.checked, name);
  };

  const onChangeInput = (
    e: React.ChangeEvent<HTMLInputElement>,
    name: keyof IObjectsFormAddTaskData
  ) => {
    setInput(e.target.value, name);
  };

  const onChangeSelect = (
    data: IObjectsFormAddTaskDataSelect,
    name: keyof IObjectsFormAddTaskData
  ) => {
    setSelectSelectedValue(data, name);

    if (name === "typicalTasks") {
      setShowSecondStage(true);

      setInput(data.label, "title");
    }
  };

  const translateFile = (file) => {
    let reader = new FileReader();

    reader.onload = function () {
      // @ts-ignore
      let result = [...new Uint8Array(reader.result)]
        .map((item) => item.toString(16).padStart(2, "0"))
        .join("");

      setFilesToUpload([
        {
          name: file.name,
          ext: getExtension(file.name),
          data: result,
        },
      ]);

      return result;
    };

    reader.readAsArrayBuffer(file);
  };

  function getExtension(filename: string): string {
    return filename.substring(filename.lastIndexOf('.') + 1);
  }

  const onChangeFile = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    let filesDataArr: IEditingObjectsFile[] = [];

    if (files) {
      filesDataArr = [
        {
          id: nanoid(6),
          name: files[0].name,
          type: files[0].type,
          size: Math.trunc(files[0].size / 1024).toLocaleString(),
        },
      ];

      translateFile(files[0]);

      setFile(filesDataArr[0]);
    }
  };

  const onDeleteFileHandler = (id: string | number, name: string) => {
    setFile({ id: "", link: "", name: "", size: "", type: "" });

    setFilesToUpload((state) => {
      const newState: any = [];

      if (state) {
        // @ts-ignore
        for (let file of state) {
          if (file.name !== name) newState.push(file);
        }
      }

      return newState;
    });
  };

  const onChangeTextArea = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    name: keyof IObjectsFormAddTaskData
  ) => {
    setInput(e.target.value, name);
  };

  const onChangeObservers = (ids: number[]) => {
    setSelectObservers(ids);
  };

  const collectData = (): object => {
    const dataObject: any = {
      objectId: isAddTask
        ? formData.objects.selectedValue.value
        : formData.objectId,
      private: formData.private,
      typicalTaskId: formData.typicalTasks.selectedValue.value,
      title: formData.title,
      priority: formData.priorities.selectedValue.value,
      executor: formData.executor.selectedValue.value,
      delegation: formData.delegation,
      spectators: formData.spectators.usersIds,
      startDate: formData.startDate,
      endDate: formData.endDate,
      files: filesToUpload ?? [],
      description: formData.description,
    };

    if (formData.objectSectionData?.length) {
      dataObject.objectSectionData = formData.objectSectionData;
    }

    return dataObject;
  };

  const onSubmitHandler = () => {
    setCheckTrigger(nanoid(6));

    let isFormHaveError = true;
    let data: any = {};

    // data = collectData();
    // createTask(data);
    // return;

    setTimeout(() => {
      isFormHaveError =
        !!formTasksRef.current?.querySelector('[class*="error"]');

      if (!isFormHaveError) {
        data = collectData();
        createTask(data);
      }
    });
  };

  const showFullFormHandler = () => {
    setShowFullForm((state) => !state);
  };

  useEffect(() => {
    getObjects();
    if (typicalTasksForSelect.length < typicalTasksMeta?.total || !typicalTasksMeta) {
      dispatch(getTypicalTasksBp());
    }

  }, []);

  useEffect(() => {
    setDefaultValues();
    setTaskFormData(null, additionalFields);
  }, [additionalFields]);

  useEffect(() => {
    if (executor) {
      setSelectSelectedValue(executor, "executor");
    }

    if (executorsAll.length) {
      setSelectValues(executorsAll, "executor");
    }

    if (description.length) {
      setInput(description, "description");
    }

    setCheckbox(delegate, "delegation");
  }, [executor, executorsAll, delegate, description]);

  useEffect(() => {
    if (formData?.startDate) {
      checkDateHelper(formData.startDate, "startDate");
    }

    if (formData?.endDate) {
      checkDateHelper(formData.endDate, "endDate");
    }
  }, [formData.startDate, formData.endDate]);

  useEffect(() => {
    if (!isAddTask) {
      if (isFormOpen) {
        if (formData.objectId.toString().length) {
          const selectedObject = objects.filter(
            (object) => object.id === formData.objectId
          );

          if (selectedObject.length) {
            setSelectSelectedValue(
              { value: selectedObject[0].id, label: selectedObject[0].name },
              "objects"
            );
          }

          setShowSecondStage(false);
          setShowFullForm(false);

          getObjectSectionData(formData.objectId);
        }
      }
    } else {
      setShowSecondStage(false);
      setShowFullForm(false);

      if (formData.objects.selectedValue.value) {
        getObjectSectionData(formData.objects.selectedValue.value);
      }
    }
  }, [isFormOpen, formData.objectId, formData.objects.selectedValue.value]);


  // обработка для получения значений в SimpleSelect -> Select (typicalTasks)
  const isMounted = useRef(false);
  const onScroll =  (e: React.UIEvent<HTMLDivElement>) => {
    isMounted.current = true;
    setIsScrolledToBottom(true)
  }

  useEffect(() => {
    if (isMounted.current && isScrolledToBottom) {
      isMounted.current = false;
      if (currentPage !== undefined && currentPage < typicalTasksMeta?.last_page) {
        if (typicalTasksForSelect.length < typicalTasksMeta?.total) {
          dispatch(setCurrentPageTypicalTasksBp(currentPage + 1));
          dispatch(getTypicalTasksBp({}, 'add'));
        }
      }
      setIsScrolledToBottom(false);
      isMounted.current = false;
    }
  }, [isScrolledToBottom]);

  const onSearchTypicalTasks = (value: string) => {
    if (value.length > 1) {
      dispatch(setSearchTypicalTasksBp(value));
    }

    if (value.length === 0) {
      dispatch(setCurrentPageTypicalTasksBp(1));
      dispatch(setSearchTypicalTasksBp(''));
    }
  }


  return (
    <div
      className={"form-add-task" + (isFormOpen ? " form-add-task--open" : "")}
      ref={formTasksRef}
    >
      <div className="form-add-task__content-wr">
        <div className="form-add-task__content">
          <div className="form-add-task__title-wr">
            <h2 className="form-add-task__title">
              <div className="form-add-task__title-text">Добавляем задачу</div>
              <div className="form-add-task__title-chk">
                <CheckboxMui
                  title="Личная"
                  checked={formData.private}
                  handleChange={(e) => onChangeCheckbox(e, "private")}
                />
              </div>
            </h2>

            <button className="form-add-task__close" onClick={onCloseHandler}>
              <CloseIcon />
            </button>
          </div>

          <div className="form-add-task__blocks">
            <div className="form-add-task__block">
              <div className="form-add-task__block-title">
                Выберите типовую задачу
              </div>

              <div className="form-add-task__block-items">
                <div className="form-add-task__block-item">
                  <div className="form-add-task__block-item-left">
                    <div className="form-add-task__block-item-title">
                      * Объект
                    </div>
                  </div>

                  <div className="form-add-task__block-item-right">
                    <div className="form-add-task__block-item-select">
                      <SelectSimple
                        isDisabled={isAddTask ? false : true}
                        name="objects"
                        selectedValue={formData.objects.selectedValue}
                        dataForSelect={
                          isAddTask ? objectsForSelect : formData.objects.data
                        }
                        onChange={(e) => onChangeSelect(e, "objects")}
                        rules={[{ required: true }]}
                        checkTriggerExternal={checkTrigger}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-add-task__block-item">
                  <div className="form-add-task__block-item-left">
                    <div className="form-add-task__block-item-title">
                      * Этап
                    </div>
                  </div>

                  <div className="form-add-task__block-item-right">
                    <div className="form-add-task__block-item-select">
                      <SelectSimple
                        isDisabled={true}
                        name="stages"
                        selectedValue={formData.stages.selectedValue}
                        dataForSelect={formData.stages.data}
                        onChange={(e) => onChangeSelect(e, "stages")}
                      />
                    </div>
                  </div>
                </div>

                <div className="form-add-task__block-item">
                  <div className="form-add-task__block-item-left">
                    <div className="form-add-task__block-item-title">
                      * Типовая задача
                    </div>
                  </div>

                  <div className="form-add-task__block-item-right">
                    <div className="form-add-task__block-item-select">
                      <SelectSimple
                        name="typicalTasks"
                        selectedValue={formData.typicalTasks.selectedValue}
                        dataForSelect={typicalTasksForSelect}
                        onChange={(e) => onChangeSelect(e, "typicalTasks")}
                        rules={[{ required: true }]}
                        checkTriggerExternal={checkTrigger}
                        onScroll={onScroll}
                        onSearchTypicalTasks={onSearchTypicalTasks}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {showSecondStage && (
              <>
                <div className="form-add-task__block">
                  <div className="form-add-task__block-title">
                    Описание задачи
                  </div>

                  <div className="form-add-task__block-items">
                    <div className="form-add-task__block-item">
                      <div className="form-add-task__block-item-left">
                        <div className="form-add-task__block-item-title">
                          * Наименование <br /> задачи
                        </div>
                      </div>

                      <div className="form-add-task__block-item-right">
                        <div className="form-add-task__block-item-input">
                          <InputSimple
                            name="title"
                            onChange={(e) => onChangeInput(e, "title")}
                            value={formData.title}
                            rules={[{ required: true }, { max: 200 }]}
                            checkTriggerExternal={checkTrigger}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-add-task__block-item">
                      <div className="form-add-task__block-item-left">
                        <div className="form-add-task__block-item-title">
                          * Приоритет
                        </div>
                      </div>

                      <div className="form-add-task__block-item-right">
                        <div className="form-add-task__block-item-input">
                          <SelectSimple
                            name="priorities"
                            selectedValue={formData.priorities.selectedValue}
                            dataForSelect={formData.priorities.data}
                            onChange={(e) => onChangeSelect(e, "priorities")}
                            props={{
                              formatOptionLabel: (data) => (
                                <PriorityCustom priorityName={data.label} />
                              ),
                            }}
                            rules={[{ required: true }]}
                            checkTriggerExternal={checkTrigger}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-add-task__block-item">
                      <div className="form-add-task__block-item-left">
                        <div className="form-add-task__block-item-title">
                          * Исполнитель
                        </div>
                      </div>

                      <div className="form-add-task__block-item-right">
                        <div className="form-add-task__block-item-select">
                          <SelectSimple
                            name="executor"
                            selectedValue={formData.executor.selectedValue}
                            dataForSelect={formData.executor.data}
                            onChange={(e) => onChangeSelect(e, "executor")}
                            rules={[{ required: true }]}
                            checkTriggerExternal={checkTrigger}
                          />
                        </div>

                        <div className="form-add-task__block-item-chk-wr">
                          <div className="form-add-task__block-item-chk">
                            <CheckboxMui
                              title="Разрешить делегирование"
                              checked={formData.delegation}
                              handleChange={(e) =>
                                onChangeCheckbox(e, "delegation")
                              }
                            />
                          </div>
                          <div className="form-add-task__block-item-info">
                            <TooltipQuestion title="Разрешить делегирование" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-add-task__block-item">
                      <div className="form-add-task__block-item-left">
                        <div className="form-add-task__block-item-title">
                          Наблюдатели
                        </div>
                      </div>

                      <div className="form-add-task__block-item-right">
                        <div className="form-add-task__block-item-select-wr">
                          <div className="form-add-task__block-item-select">
                            <SelectObserversObjects
                              onChangeObservers={(data) =>
                                onChangeObservers(data)
                              }
                            />
                          </div>
                          <div className="form-add-task__block-item-info">
                            <TooltipQuestion title="Наблюдатели" />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="form-add-task__block-item">
                      <div className="form-add-task__block-item-left">
                        <div className="form-add-task__block-item-title">
                          * Начало
                        </div>
                      </div>

                      <div className="form-add-task__block-item-right">
                        <div className="form-add-task__block-item-input">
                          <DatePickerTrue
                            format={datePickerFormat.STANDARD_DATE_TIME}
                            minDateMessage={datePickerText.MIN_DATE_MESSAGE}
                            maxDateMessage={datePickerText.MAX_DATE_MESSAGE}
                            errorMessages={dateErrorMessages.startDate}
                            date={moment(
                              formData.startDate,
                              datePickerFormat.STANDARD_DATE_TIME
                            )}
                            onChangeDate={(date) =>
                              onChangeDate(date, "startDate")
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-add-task__block-item">
                      <div className="form-add-task__block-item-left">
                        <div className="form-add-task__block-item-title">
                          * Дедлайн
                        </div>
                      </div>

                      <div className="form-add-task__block-item-right">
                        <div className="form-add-task__block-item-input">
                          <DatePickerTrue
                            format={datePickerFormat.STANDARD_DATE_TIME}
                            minDateMessage={datePickerText.MIN_DATE_MESSAGE}
                            maxDateMessage={datePickerText.MAX_DATE_MESSAGE}
                            errorMessages={dateErrorMessages.endDate}
                            date={moment(formData.endDate)}
                            onChangeDate={(date) =>
                              onChangeDate(date, "endDate")
                            }
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-add-task__block-item">
                      <div className="form-add-task__block-item-left">
                        <div className="form-add-task__block-item-title">
                          Прикрепить файл
                        </div>
                      </div>

                      <div className="form-add-task__block-item-right">
                        <div className="form-add-task__block-item-file-add-btn-wr">
                          <button className="form-add-task__block-item-file-add-btn">
                            <input
                              className="form-add-task__block-item-file-add-btn-input"
                              type="file"
                              multiple={false}
                              onClick={(e) => (e.currentTarget.value = "")}
                              onChange={onChangeFile}
                            />
                            <span className="form-add-task__block-item-file-add-btn-title">
                              Выбрать
                            </span>
                          </button>
                        </div>

                        {formData.file?.name.length > 0 && (
                          <div className="form-add-task__block-item-file-info">
                            <FileItem
                              data={formData.file}
                              onDelete={onDeleteFileHandler}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-add-task__block">
                  <div className="form-add-task__block-title">
                    Исполнение задачи
                  </div>

                  <div className="form-add-task__block-items">
                    <div className="form-add-task__block-item">
                      <div className="form-add-task__block-item-left">
                        <div className="form-add-task__block-item-title">
                          Описание задачи
                        </div>
                      </div>

                      <div className="form-add-task__block-item-right">
                        <TextAreaSimple
                          name="description"
                          onChange={(e) => onChangeTextArea(e, "description")}
                          value={formData.description}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>

          {showSecondStage &&
            (additionalFields.checklist?.length > 0 || additionalFields.extra?.length > 0) && (
              <>
                <div className="form-add-task__full-form">
                  <button
                    className="form-add-task__full-form-btn"
                    onClick={showFullFormHandler}
                  >
                    <span className="form-add-task__full-form-btn-ico">
                      <TableIcon />
                    </span>

                    <span className="form-add-task__full-form-btn-text">
                      {!showFullForm && (
                        <>Показать полную форму исполнения задачи</>
                      )}
                      {showFullForm && (
                        <>Скрыть полную форму исполнения задачи</>
                      )}
                    </span>
                  </button>

                  {showFullForm && (
                    <div className="form-add-task__full-form-content">
                      <div className="form-add-task__full-form-content-disabled"></div>

                      <div className="form-add-task__full-form-block">
                        <AdditionalBlock data={additionalFields} />
                      </div>
                    </div>
                  )}
                </div>
              </>
            )}

          <div className="form-add-task__btns">
            {showSecondStage && (
              <>
                <button
                  className={
                    "form-add-task__btn form-add-task__btn--submit" +
                    (isCreatingTask
                      ? " form-add-task__btn--show-preloader"
                      : "")
                  }
                  onClick={onSubmitHandler}
                >
                  <span className="form-add-task__btn-title">
                    Добавить задачу
                  </span>
                  <span className="form-add-task__btn-preloader">
                    <PreloaderLocal />
                  </span>
                </button>
              </>
            )}

            <button
              className="form-add-task__btn form-add-task__btn--cancel"
              onClick={onCloseHandler}
            >
              <span className="form-add-task__btn-title">Отменить</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: State) => ({
  typicalTasksMeta: state.typicalTasksPage.typicalTasksMeta,
  currentPage: state.businessPage.extraFormBP.currentPageTypicalTasks,
  isCreatingTask: state.objectsFormAddTask.isCreatingTask,
  isFormOpen: state.objectsFormAddTask.isFormOpen,
  formData: state.objectsFormAddTask.data,
  typicalTasksForSelect: getTypicalTasksForSelect(state),
  executor: getTypicalTaskExecutor(state),
  executorsAll: getAllExecutors(state),
  delegate: getDelegate(state),
  duration: getDuration(state),
  description: getDescription(state),
  additionalFields: getAdditionalFields(state),
  objects: state.objectsPage.objects,
  objectsForSelect: getObjectsTasksForSelect(state),
  checkTrigger: state.objectsFormAddTask.checkTrigger
});

const mapDispatchToProps = {
  setSelectValues,
  setSelectSelectedValue,
  setCheckbox,
  setInput,
  getTypicalTasks,
  setSelectObservers,
  setFile,
  setFormOpen,
  setCheckTrigger,
  createTask,
  getObjectSectionData,
  setTaskFormData,
  setDefaultValues,
  getObjects,
  setFormDefaultValues
};

export default connect(mapStateToProps, mapDispatchToProps)(FormAddTask);
